.ftr-card {
  background: $colorGravity;
  margin-bottom: 2rem;

  header {
    padding: 1rem;
    box-shadow: 0px 2px 0px #000000BF;
    border-radius: 4px 4px 0px 0px;
    font-family: 'Bandwidth';
  }
  section {
    padding: 1rem;
  }
}

.card {
  .text-muted {
    --bs-text-opacity: 1;
  }

  .mb-2 {
    margin-bottom: 0.5rem!important;
  }
}