.mqtt-control-server {
  .nav-link {
    color: #efefef;
  }
  .nav-pills .nav-link.active {
    background-color: #96FFF5;
    color: #000;
  }
  pre {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #333;
    background: #efefef;
    color: #000;
    font-size: 0.7rem;
  }
}
