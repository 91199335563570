.cms-alarms {
  tr.changed {
    border: 1px solid $colorElectricity;
    border-width: thick;
  }  
  td.alarm-type-col {
    width: 1rem;
  }
  input {    
    inline-size: -webkit-fill-available;
  }
  td.del-buttons {
    width: 1rem;
  }
}

.cms-alarm-list {
  button {
    margin: 0px 5px
  }
  
}