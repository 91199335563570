

.cms-rgmanage {

  .rgMgt-Title {
    font-family: 'Helvetica';
    font-weight: bolder;
    font-size: 2em;
  }

  .rgmgt-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 10em;
    justify-content: center;
  }

  .headerItems {
    display: flex;
    align-items: center;    
    justify-content: space-evenly;
  }

  .rgmt-table-btns {
    width: 8em;
  }

  td {
    padding: 2em 0em 2em 0em;
  }

  // button {
  //   margin: 0px 5px
  // }

  img {
  //   font-family: 'Helvetica';
  //   font-weight: 300;
  //   // line-height: 2;
  //   // text-align: center;
  //   top: 50%;
  //   left: 50%;
    height: 10rem;
  //   display: block;
    
  }

  // img:before {
  //   content: " ";
  //   display: block;
  
  //   position: absolute;
  //   top: -10px;
  //   left: 0;
  //   height: calc(100% + 10px);
  //   width: 100%;
  //   background-color: rgb(230, 230, 230);
  //   border: 2px dotted rgb(200, 200, 200);
  //   border-radius: 5px;
  // }
  
  img:after {
    content: " Missing Image ";

    font-size: 2em;
    font-family: FontAwesome;
    color: rgb(100, 100, 100);
  
    display: block;
    z-index: 2;
    top: 0;
    left: 0;
    // width: 100px;
    height: 10rem;
    background-color: #fff;
  }

}