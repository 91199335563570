.spark {
  .timestamp {
    margin-top: -4rem;
    float: right;
  
    p {
      display: inline-block;
      margin-right: 2rem;
    }
  }
}