#heatmap-wrapper {
    color: #000;
}
#chart text {
    fill: #fff;
}
#chart .apexcharts-legend-text {
    color: #fff!important;
}
#heatmap-outer-wrapper {
    margin-top: 1rem;
    position: relative;

    .date-picker {
        z-index: 3;
        margin-bottom: 2rem;
        position: relative;

        .alert {
            margin-top: 2rem;
        }

        .react-datepicker-wrapper input {
            font-size: 2rem;;
            padding: 0.5rem 1rem;
        }
    }

    #heatmap-wrapper {
        position: relative;
    }
}
.heatmap {
    background-color: #ffffff;
    width: 100%;
    height: 800px;
    z-index: 1;
    position: relative;
}
#heatmap-wrapper .hm-tooltip { 
    position:absolute;
    left:0;
    top:0;
    background:rgba(0,0,0,.8);
    color:white;
    font-size:14px;
    padding:5px;
    line-height:18px;
    display:none;
    z-index: 2;
}
.konvajs-content {
    width: 100% !important;
    overflow: hidden;
}