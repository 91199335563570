.konvajs-content {
    border: 2px solid $colorElectricity
}

.main-stage {
    position: relative;
    
    .heatmap-popup {
        position: absolute;
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0 0 5px grey;
        z-index: 10;
        background-color: white;
        color: $colorGravity;
        font-size: 1rem;
        line-height: 1.2rem;

        p {
            margin-bottom: 0.5rem;
            span.label {
                width: 5rem;
                font-weight: bold;
                padding-right: 0.5rem;;
                display: inline-block;
            }
        }
    }
}
.heatmap-controls p {
    font-size: 1.5rem;
    line-height: 3rem;
    button {
        margin-left: 2rem;
    }
}